import { Component, HostListener, Input, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { GameService } from '../services/game.service';

@Component({
  selector: 'app-game-tile',
  templateUrl: './game-tile.component.html',
  styleUrls: [ './game-tile.component.scss' ]
})
export class GameTileComponent implements OnInit, OnDestroy  {

  @Input() rowIndex: number;
  @Input() cellIndex: number; 

  tileLabel: string;
  destroy = new Subject();
  
  constructor (private gameService: GameService) {
    this.tileLabel = '';
  }

  setTileLabel() {
    this.tileLabel = this.gameService.getTileLabel(this.rowIndex, this.cellIndex);
  }

  ngOnInit() {
    this.setTileLabel();

    this.gameService.gameResetSubject.pipe(takeUntil(this.destroy)).subscribe(resetDate => {
      if (resetDate) {
        this.tileLabel = '';
      }
    });
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  @HostListener('click', ['$event.target'])
  onClick(element) {
    const selectionResponse = this.gameService.registerPlayerSelection(this.rowIndex, this.cellIndex);

    if ( selectionResponse.status === 200 && selectionResponse.label ) {
      this.tileLabel = selectionResponse.label;
    } else if(selectionResponse.message) {
      console.log(selectionResponse.message);
    }
  }
}
