<ng-container *ngIf="gameStatus && !gameStatus.whosTurn">
  <p>Who will start the game?</p>
  <div>
    <button type="button" class="btn primary" (click)="onWhoStarts('X')">Player X</button>
    <button type="button" class="btn primary" (click)="onWhoStarts('O')">Player O</button>
  </div>
</ng-container>

<p *ngIf="gameStatus && gameStatus.whosTurn && !gameStatus.winner && !gameStatus.isStalemate">It's your turn {{(gameStatus.whosTurn.name)}}.</p>

<p *ngIf="gameStatus && gameStatus.winner">{{gameStatus.winner.name}} wins!</p>

<p *ngIf="gameStatus && (!gameStatus.winner && gameStatus.isStalemate)">Stalemate. Try Again?</p>

<div *ngIf="gameStatus && (gameStatus.winner || (!gameStatus.winner && gameStatus.isStalemate))">
  <button type="button" class="btn primary" (click)="restartGame()">Rematch</button>
  <button type="button" class="btn primary" (click)="resetGame()">Start Over</button>
</div>

<div class="game-board" *ngIf="gameStatus && gameStatus.whosTurn">
  <div class="game-board-row" *ngFor="let row of [].constructor(3); let r = index;">
    <app-game-tile *ngFor="let cell of [].constructor(3); let c = index;" [rowIndex]="r" [cellIndex]="c" [ngClass]="{'top-bottom-border': (r === 1), 'left-right-border': (c === 1)}"></app-game-tile>
  </div>
</div>

<div class="scoreboard" *ngIf="gameStatus && gameStatus.whosTurn">
  <p *ngIf="playerX">{{playerX.name}} Points: {{playerX.points}}</p>
  <p *ngIf="playerO">{{playerO.name}} Points: {{playerO.points}}</p>
</div>
