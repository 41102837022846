import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { GameBoardComponent } from './components/game-board.component';
import { GameTileComponent } from './components/game-tile.component';

@NgModule({
  declarations: [GameBoardComponent, GameTileComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [ GameBoardComponent ]
})
export class GameModule { }
