import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { GameService } from '../services/game.service';
import { GameStatus } from '../model/game-status';
import { Player } from '../model/player';

@Component({
  selector: 'app-game-board',
  templateUrl: './game-board.component.html',
  styleUrls: ['./game-board.component.scss']
})
export class GameBoardComponent implements OnInit, OnDestroy {

  destroy = new Subject();
  gameStatus: GameStatus;
  playerX: Player;
  playerO: Player;

  constructor (private gameService: GameService) { }

  ngOnInit() {
    let self = this;
    this.gameService.initializeGame(true);

    this.gameService.gameStatusSubject.pipe(takeUntil(this.destroy)).subscribe(gameStatus => {
      if (gameStatus) {
        self.gameStatus = gameStatus;
      }
    });

    this.gameService.playerXSubject.pipe(takeUntil(this.destroy)).subscribe(playerX => {
      if (playerX) {
        self.playerX = playerX;
      }
    });

    this.gameService.playerOSubject.pipe(takeUntil(this.destroy)).subscribe(playerO => {
      if (playerO) {
        self.playerO = playerO;
      }
    });
  }

  onWhoStarts(whoStarts: string) {
    this.gameService.startGame(whoStarts === 'X');
  }

  resetGame() {
    this.gameService.initializeGame(true);
  }

  restartGame() {
    this.gameService.initializeGame(false);
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }
}
