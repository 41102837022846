import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { Player } from "../model/player";
import { GameConfiguration } from "../model/game-configuration";
import { GameStatus } from "../model/game-status";
import { PlayerSelectionResponse } from "../model/player-selection-response";

@Injectable({
  providedIn: "root",
})
export class GameService {
  private playerX: Player;
  private playerXDefaults: Player;
  playerXSubject: BehaviorSubject<Player> = new BehaviorSubject(null);

  private playerO: Player;
  private playerODefaults: Player;
  playerOSubject: BehaviorSubject<Player> = new BehaviorSubject(null);

  private gameConfig: GameConfiguration;
  gameConfigSubject: BehaviorSubject<GameConfiguration> = new BehaviorSubject(
    null
  );

  private gameStatus: GameStatus;
  private gameStatusDefaults: GameStatus;
  gameStatusSubject: BehaviorSubject<GameStatus> = new BehaviorSubject(null);
  gameResetSubject: BehaviorSubject<Date> = new BehaviorSubject(null);

  /**
   * The values assigned to each game tile.
   */
  private gameData: Array<Array<number>>;

  constructor() {
    this.playerXDefaults = {
      id: "P1",
      name: "Player X",
      points: 0,
      tileChoiceLabel: "X",
      tileChoiceValue: 1,
      winningScore: 3,
    };
    this.playerODefaults = {
      id: "P2",
      name: "Player O",
      points: 0,
      tileChoiceLabel: "O",
      tileChoiceValue: 0,
      winningScore: 0,
    };
    this.gameConfig = { defaultTileValue: 5, maxTurnCount: 9 };
    this.notifyGameConfigurationListeners();
    this.gameStatus = this.gameStatusDefaults = {
      turnCount: 0,
      whosTurn: null,
      winner: null,
      isStalemate: false,
    };
    this.initializeGame(true);
  }

  initializeGame(resetPlayerNames: boolean = false) {
    if (resetPlayerNames) {
      this.playerX = Object.assign({}, this.playerXDefaults);
      this.playerO = Object.assign({}, this.playerODefaults);
    } else {
      this.playerX = Object.assign({}, this.playerXDefaults, {
        name: this.playerX ? this.playerX.name : this.playerXDefaults.name,
        points: this.playerX
          ? this.playerX.points
          : this.playerXDefaults.points,
      });
      this.playerO = Object.assign({}, this.playerODefaults, {
        name: this.playerO ? this.playerO.name : this.playerODefaults.name,
        points: this.playerO
          ? this.playerO.points
          : this.playerODefaults.points,
      });
    }

    // Reset the game tiles back to default values
    const tileVal = this.gameConfig.defaultTileValue;
    this.gameData = [
      [tileVal, tileVal, tileVal],
      [tileVal, tileVal, tileVal],
      [tileVal, tileVal, tileVal],
    ];

    const whosTurn: Player = resetPlayerNames
      ? null
      : this.gameStatus.whosTurn.id === this.playerX.id
        ? this.playerO
        : this.playerX;
    this.gameStatus = Object.assign({}, this.gameStatusDefaults, { whosTurn });

    // Notify BehaviorSubject listeners of changes
    this.notifyPlayerXListeners();
    this.notifyPlayerOListeners();
    this.notifyGameStatusListeners();
    this.notifyGameResetListeners();
  }

  startGame(playerXStarts: boolean = true) {
    const whosTurn: Player = playerXStarts ? this.playerX : this.playerO;
    this.gameStatus = Object.assign({}, this.gameStatusDefaults, { whosTurn });
    this.notifyGameStatusListeners();
  }

  checkScores() {
    let gd = this.gameData;

    // Check the rows
    for (let r: number = 0; r < gd.length; r++) {
      let rowScore = gd[r].reduce((a, b) => a + b, 0);

      if (rowScore === this.playerX.winningScore) {
        this.gameStatus.winner = this.playerX;
        this.incrementPlayerXPoints();
        break;
      } else if (rowScore === this.playerO.winningScore) {
        this.gameStatus.winner = this.playerO;
        this.incrementPlayerOPoints();
        break;
      }
    }

    // Check the columns
    if (!this.gameStatus.winner) {
      for (let c: number = 0; c < gd.length; c++) {
        let colScore = gd[0][c] + gd[1][c] + gd[2][c];

        if (colScore === this.playerX.winningScore) {
          this.gameStatus.winner = this.playerX;
          this.incrementPlayerXPoints();
          break;
        } else if (colScore === this.playerO.winningScore) {
          this.gameStatus.winner = this.playerO;
          this.incrementPlayerOPoints();
          break;
        }
      }
    }

    // Check diagonals
    if (!this.gameStatus.winner) {
      let d1Score = gd[0][0] + gd[1][1] + gd[2][2];
      let d2Score = gd[0][2] + gd[1][1] + gd[2][0];

      if (
        d1Score === this.playerX.winningScore ||
        d2Score === this.playerX.winningScore
      ) {
        this.gameStatus.winner = this.playerX;
        this.incrementPlayerXPoints();
      } else if (
        d1Score === this.playerO.winningScore ||
        d2Score === this.playerO.winningScore
      ) {
        this.gameStatus.winner = this.playerO;
        this.incrementPlayerOPoints();
      }
    }

    if (this.gameStatus.turnCount === this.gameConfig.maxTurnCount) {
      this.gameStatus.isStalemate = true;
    }

    if (!!this.gameStatus.winner) {
      this.notifyGameStatusListeners();
    }
  }

  notifyPlayerXListeners() {
    this.playerXSubject.next(Object.assign({}, this.playerX));
  }

  notifyPlayerOListeners() {
    this.playerOSubject.next(Object.assign({}, this.playerO));
  }

  notifyGameConfigurationListeners() {
    this.gameConfigSubject.next(Object.assign({}, this.gameConfig));
  }

  notifyGameStatusListeners() {
    this.gameStatusSubject.next(Object.assign({}, this.gameStatus));
  }

  notifyGameResetListeners() {
    this.gameResetSubject.next(new Date());
  }

  setPlayerXName(name: string = "Player X") {
    this.playerX.name = name;
    this.notifyPlayerXListeners();
  }

  incrementPlayerXPoints() {
    this.playerX.points++;
    this.notifyPlayerXListeners();
  }

  setPlayerOName(name: string = "Player O") {
    this.playerO.name = name;
    this.notifyPlayerOListeners();
  }

  incrementPlayerOPoints() {
    this.playerO.points++;
    this.notifyPlayerOListeners();
  }

  registerPlayerSelection(
    rowIndex: number,
    cellIndex: number
  ): PlayerSelectionResponse {
    // 409 indicates a conflict. Send this when the requested game data point has already been chosen.
    const takeNoActionStatus: number = 409;
    const successStatus: number = 200;

    if (this.gameStatus.winner) {
      return { status: takeNoActionStatus, message: "The game is over." };
    }

    if (!this.gameStatus.whosTurn) {
      return {
        status: takeNoActionStatus,
        message: "A player has not yet been selected to start the game.",
      };
    }

    let currentTileValue = this.gameData[rowIndex][cellIndex];

    if (currentTileValue === this.gameConfig.defaultTileValue) {
      let selectionResponse: PlayerSelectionResponse = {
        status: successStatus,
        label: "",
      };
      this.gameStatus.turnCount++;
      this.gameData[rowIndex][
        cellIndex
      ] = this.gameStatus.whosTurn.tileChoiceValue;
      selectionResponse.label = this.getTileLabel(rowIndex, cellIndex);
      this.checkScores();

      if (!this.gameStatus.winner) {
        this.gameStatus.whosTurn =
          this.gameStatus.whosTurn.id === this.playerX.id
            ? this.playerO
            : this.playerX;
      }

      this.notifyGameStatusListeners();

      return selectionResponse;
    }

    return {
      status: takeNoActionStatus,
      message: "This tile has already been selected.",
    };
  }

  getTileLabel(rowIndex: number, cellIndex: number): string {
    let gd = this.gameData;
    let cellVal = gd[rowIndex][cellIndex];

    if (cellVal === this.gameConfig.defaultTileValue) {
      return "";
    }

    return this.gameStatus && this.gameStatus.whosTurn
      ? this.gameStatus.whosTurn.tileChoiceLabel
      : "";
  }
}
